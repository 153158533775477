import React, {useCallback, useEffect, useState} from "react";

import "./Chance.css";
import {Button} from "react-bootstrap";
import moment from "moment";

type Ticket = {
    exhibit_name: string;
    timestamp: string;
    claimed_by_user?: string;
};

export const TicketView = () => {
    const [errorState, setErrorState] = useState(false);
    const [ticketState, setTicketState] = useState<Ticket | null>(null);

    const timestamp = ticketState != null ? ticketState.timestamp : null;
    const claimedBy = ticketState != null ? ticketState.claimed_by_user : null;
    const timeAgo = timestamp != null ? moment.utc(timestamp).fromNow() : null;

    const chance_api_endpoint = process.env.REACT_APP_CHANCE_API_ENDPOINT;
    const chance_api_key = process.env.REACT_APP_CHANCE_API_KEY || '';

    const handleTicketResponse = async (ticketPromise: Promise<Response>) => {
        try {
            const response = await ticketPromise;

            if (!response.ok) {
                throw new Error('Network response was bad');
            }

            const ticketResponse = await response.json();
            setTicketState(ticketResponse);

        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
            setErrorState(true);
        }
    }

    const handleClick = useCallback(async () => {
        const ticketPromise = fetch(`${chance_api_endpoint}/api/ticket`, {
            method: 'POST',
            headers: {'x-api-key': chance_api_key},
        })
        await handleTicketResponse(ticketPromise);
    }, [chance_api_endpoint, chance_api_key])

    useEffect(() => {
        const getLatestTicket = async () => {
            const ticketPromise = fetch(`${chance_api_endpoint}/api/ticket`, {
                method: 'GET',
            })
            await handleTicketResponse(ticketPromise);
        }

        getLatestTicket().catch(console.error);
        const interval = setInterval(() => {
            getLatestTicket().catch(console.error);
        }, 15000);
        return () => clearInterval(interval);
    }, [chance_api_endpoint]);

    const currentUrl = window.location.href;
    const httpsUrl = currentUrl.replace('http:', 'https:');
    const errorContent = errorState ? (
        <div>
            <h3>Error: Please get in contact with ArtPro if you see this</h3>
            <h4>Troubleshooting steps:</h4>
            <a href={httpsUrl}>Ensure you are using HTTPS</a>
        </div>
    ) : <></>

    return (
        <div className="chance TicketView">
            <h1>Chance Dashboard</h1>

            <Button key="TicketView.Button" bsStyle="dark" onClick={handleClick}>Release Ticket</Button>

            <h3>Latest Ticket</h3>
            <dl>
                <dt>Ticket Created</dt>
                <dd>{timeAgo}</dd>
                <dt>Claimed By</dt>
                <dd>{claimedBy}</dd>
            </dl>

            <div className="chance ErrorState">{errorContent}</div>
        </div>
    );
};
